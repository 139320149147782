<template>
  <div class="app donate">
    <Navigation/>
    <div class="home-content donate-content">
      <img class="bitcoin-qr" src="../../assets/img/bitcoin.png" alt="">
      <img class="bitcoin-logo" src="https://upload.wikimedia.org/wikipedia/commons/c/c5/Bitcoin_logo.svg" alt="">
    </div>
  </div>
</template>

<script>
import Navigation from '../../components/HomeNav.vue'

export default {
  name: 'Donate',
  components: {
    Navigation
  }
}
</script>

<style lang="scss" scoped>
.bitcoin-qr {
  width: 200px;
  margin: 0 auto;
}
.bitcoin-logo {
  width: 100px;
  margin: 20px auto 0;
}
</style>
